export enum StatusEnum {
    NEUTRAL = 'neutral',
    INFORMATIVE = 'informative',
    NOTICE = 'notice',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}

export enum ModalNameEnum {
    AB_QUERY = 'ab-query',
    UPLOAD_LIST = 'upload-list',
}

export enum ButtonActionEnum {
    DELETE_ONE = 'delete-one',
    DELETE_MULTIPLE = 'delete-multiple',
}

export enum MessageButtonTypeEnum {
    BACK = 'back',
    CANCEL = 'cancel',
    SCHEDULE = 'schedule',
    UNSCHEDULE = 'unschedule',
    SEND_TEST = 'sendTest',
    SEND_TO_ME = 'sendToMe',
    SEND_TO_ONE = 'sendToOne',
}

export enum TypeCode {
    UNDEFINED = 0,
    NORMAL = 1,
    API_ONGOING = 2,
}
